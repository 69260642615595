// FetchHtml.js
class FetchHtml {
    /**
     * Constructs an instance of FetchHtml.
     * @param {Object} waiting - An instance of the Waiting service to handle loading states.
     */
    constructor(waiting) {
        this.waiting = waiting; // Injected instance of Waiting to manage loading indicators
    }

    /**
     * Fetches HTML content from a specified URL and loads it into a specified element.
     * @param {String} url - The URL from which to retrieve the content.
     * @param {HTMLElement|String} element - The target element or a selector string where the result will be loaded.
     * @param {Object|Function} options - Optional settings or a function to modify fetch options.
     * @returns {HTMLElement} - Returns the passed element after data loading is complete.
     */
    async fetch(url, element, options) {
        // Check if the URL is valid
        if (!url) {
            console.error('URL cannot be null or undefined');
            return; // Exit if URL is invalid
        }

        // Resolve the element to ensure it's an HTMLElement
        element = typeof element === 'string' ? document.querySelector(element) : element;

        if (!element) {
            console.error('Element not found:', element);
            return; // Exit if element is not found
        }

        // Set up default fetch options and modify with provided options
        let fetchOptions = {
            method: 'GET',
            cache: 'no-cache',
            mode: 'same-origin',
            credentials: 'same-origin',
            redirect: 'follow',
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            ...options // Merge custom options
        };

        // Show loading indicator
        this.showLoadingIndicator(element);

        try {
            const response = await fetch(url, fetchOptions);

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const responseText = await response.text();
            this.waiting.wait(false); // Hide loading indicator

            // Handle input and textarea elements
            if (element.matches('input, textarea')) {
                element.value = responseText;
            } else {
                element.innerHTML = responseText;
                this.executeScripts(responseText); // Handle embedded scripts
            }

            return element; // Return the modified element
        } catch (error) {
            console.error('Fetch error:', error);
            this.waiting.wait(false); // Hide loading indicator
        }
    }

    /**
     * Shows a loading indicator based on the element type.
     * @param {HTMLElement} element - The target element to show a loading indicator.
     */
    showLoadingIndicator(element) {
        if (element.closest('.btn') || element.matches('input, textarea')) {
            element.innerHTML = '<i class="fa-solid fa-spinner fa-spin"></i>'; // Show spinner for buttons and inputs
        } else {
            this.waiting.wait(true); // Show loading indicator using the Waiting service for other elements
        }
    }

    /**
     * Executes any scripts embedded in the fetched content.
     * @param {string} responseText - The HTML response text.
     */
    executeScripts(responseText) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(responseText, 'text/html');
        const scripts = doc.querySelectorAll('script');

        scripts.forEach(script => {
            const newScript = document.createElement('script');
            if (script.src) {
                newScript.src = script.src;
                newScript.async = true; // Load asynchronously
            } else {
                newScript.textContent = script.textContent;
            }
            document.body.appendChild(newScript); // Append script to body
        });
    }
}

// Exporting the FetchHtml class for use in other modules
export default FetchHtml;



// class FetchHtml {
//     /**
//      * Constructs an instance of FetchHtml.
//      * @param {Object} waiting - An instance of the Waiting service to handle loading states.
//      */
//     constructor(waiting) {
//         this.waiting = waiting; // Injected instance of Waiting to manage loading indicators
//     }

//     /**
//      * Fetches HTML content from a specified URL and loads it into a specified element.
//      * @param {String} url - The URL from which to retrieve the content.
//      * @param {HTMLElement|String} element - The target element or a selector string where the result will be loaded.
//      * @param {Object|Function} options - Optional settings or a function to modify fetch options.
//      * @returns {HTMLElement} - Returns the passed element after data loading is complete.
//      */
//     async fetch(url, element, options) {
//         // Check if the URL is valid
//         if (url === null) {
//             console.error('URL cannot be null');
//             return; // Exit if URL is invalid
//         }

//         // Resolve the element: if it's a string, use querySelector; if it's already an HTMLElement, use it directly
//         element = (typeof element === 'string') ? document.querySelector(element) : element;

//         // Ensure the element exists
//         if (!element) {
//             console.error('Element not found:', element);
//             return; // Exit if element is not found
//         }

//         // Default fetch options
//         let fetchOptions = {
//             method: 'GET',
//             cache: 'no-cache',
//             mode: 'same-origin',
//             credentials: 'same-origin',
//             redirect: 'follow',
//             headers: {
//                 'X-Requested-With': 'XMLHttpRequest' // Indicate that the request is AJAX
//             }
//         };

//         // Display loading spinner for buttons or input fields
//         if (element.closest('.btn') !== null || element.matches('input, textarea')) {
//             element.innerHTML = '<i class="fa-solid fa-spinner fa-spin"></i>'; // Show spinner
//         } else {
//             this.waiting.wait(true); // Show loading indicator using the Waiting service
//         }

//         // If options are provided, modify fetchOptions accordingly
//         if (options) {
//             if (typeof options === 'function') {
//                 fetchOptions = options(fetchOptions); // Call options function to modify options
//             } else if (typeof options === 'object') {
//                 Object.assign(fetchOptions, options); // Merge provided options with defaults
//             }
//         }

//         try {
//             // Perform the fetch operation
//             const response = await fetch(url, fetchOptions);
//             if (!response.ok) {
//                 throw new Error('Network response was not ok: ' + response.statusText); // Handle non-OK responses
//             }

//             const responseText = await response.text(); // Retrieve the response text
//             this.waiting.wait(false); // Hide loading indicator

//             // Handle input and textarea elements specifically
//             if (element.matches('input, textarea')) {
//                 element.value = responseText; // Set the value for inputs
//                 return element; // Return the modified element
//             } 

//             // Load the response HTML into the element
//             element.innerHTML = responseText;

//             // Parse the response to handle any embedded scripts
//             const parser = new DOMParser();
//             const doc = parser.parseFromString(responseText, "text/html");
//             const scripts = doc.documentElement.querySelectorAll('script'); // Find all script tags

//             // Execute any scripts found in the response
//             if (scripts.length) {
//                 scripts.forEach(script => {
//                     const newScript = document.createElement('script'); // Create a new script element
//                     if (script.src) {
//                         newScript.src = script.src; // Set the source for external scripts
//                         newScript.async = true; // Load asynchronously
//                     } else {
//                         newScript.textContent = script.textContent; // Set inline script content
//                     }
//                     document.body.appendChild(newScript); // Append the script to the body
//                 });
//             }
//             return element; // Return the modified element

//         } catch (error) {
//             console.log(error); // Log any errors that occur during fetch
//             this.waiting.wait(false); // Ensure loading indicator is hidden on error
//         }
//     }
// }

// // Exporting the FetchHtml class for use in other modules
// export default FetchHtml;
