/* GridManager.js */

class GridManager {
    /**
     * @param {GridResizer} gridResizer 
     */
    constructor(gridResizer) {
        this.gridResizer  = gridResizer;
    }

    /**
     * @param {HTMLElement} element 
     * @param {String} mode 
     * @param {String} itemSelector 
     * @param {String} widthSelector 
     */
    isotope(element, mode, itemSelector, widthSelector) {
        switch (mode) {
            case 'fitRows':
            case 'masonry':
                const iso = new Isotope(element, {
                    itemSelector: itemSelector,
                    layoutMode: mode,
                    transitionDuration: 600,
                    percentPosition: true,
                    masonry: { columnWidth: widthSelector }
                });

                // Reset layout on image load progress
                const imgs = new imagesLoaded(element);
                imgs.on('progress', (instance, image) => {
                    const result = image.isLoaded ? 'loaded' : 'broken';
                    console.log(`Image is ${result} for ${image.img.src}`);
                    iso.layout();
                });
                break;

            case 'resize':
                this.gridResizer.attach(element);
                break;

            default:
                console.warn(`Unknown grid mode: ${mode}`);
        }
    }
}

export default GridManager;
