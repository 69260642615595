/**
 * Grid Resizer class.
 * 
 * The resizing logic differs from Isotope.
 * - It is not necessary to specify a data-grid-item="size" element.
 * - It does not occupy the entire data-grid-mode space; it only defines 
 *   the container within which to search.
 * - The height applied to data-grid-item="master" is the tallest 
 *   height found among "grid-item" and data-grid-item="slave".
 */
class GridResizer {
    constructor(options, imagesLoaded, resizeHandler) {
        this.grids = []; // Array to hold grid elements
        this.imagesLoaded = imagesLoaded; // Dependency injection for imagesLoaded
        this.resizeHandler = resizeHandler; // Dependency injection for the resize handler
        this.options = {
            itemSelector: '[data-grid-item="master"]', // Selector for master items
            itemSelectorSlave: '[data-grid-item="slave"]', // Selector for slave items
            itemSelectorApply: '[data-grid-item="apply"]', // Selector for items to apply height
            ...options // Merge provided options with default options
        };
    }

    /**
     * Attaches a grid element and initializes resizing logic.
     * 
     * @param {HTMLElement} grid - The grid element to attach.
     */
    attach(grid) {
        this.grids.push(grid); // Add the new grid to the list

        // Load images and then perform the resize
        this.imagesLoaded(grid, (instance) => {
            instance.elements.forEach(this.resize.bind(this, grid)); // Resize each loaded element

            // Create a resize handler callback to update the grid during window resizing
            this.resizeHandler.addCallback(() => {
                requestAnimationFrame(() => this.resize(grid)); // Optimize resize calls with requestAnimationFrame
            }, 200); // Set a debounce timeout of 200ms

            // Observe changes to the parent node of the grid
            this.observeMutations(grid.parentNode, grid);
        });
    }

    /**
     * Applies resizing to all elements within the specified grid.
     * 
     * @param {HTMLElement} grid - The grid element to resize.
     */
    resize(grid) {
        const items = grid.querySelectorAll(`${this.options.itemSelector}, ${this.options.itemSelectorSlave}`);

        if (items.length > 0) {
            // Reset the height of all items
            items.forEach(item => {
                item.style.height = 'auto';
            });

            // Find the tallest item among the selected elements
            let tallest = 0;
            items.forEach(item => {
                const height = item.offsetHeight; // Get the current height of the item
                if (height > tallest) {
                    tallest = height; // Update the tallest height if necessary
                }
            });

            // Apply the tallest height to eligible elements
            const elements = grid.querySelectorAll(`${this.options.itemSelector}, ${this.options.itemSelectorApply}`);
            elements.forEach(element => {
                // Set height only if the element is smaller than 80% of the grid's width
                if (element.offsetWidth < (0.8 * grid.offsetWidth)) {
                    element.style.height = `${tallest}px`; // Apply the tallest height
                }
            });
        }
    }

    /**
     * Observes changes to the specified target node.
     * 
     * @param {HTMLElement} targetNode - The node to observe for mutations.
     * @param {HTMLElement} grid - The associated grid element.
     */
    observeMutations(targetNode, grid) {
        const observer = new MutationObserver(() => {
            observer.disconnect(); // Disconnect the observer to avoid infinite loops
            this.resize(grid); // Trigger resize on detection of changes
            observer.observe(targetNode, { attributes: true }); // Reconnect the observer
        });
        observer.observe(targetNode, { attributes: true }); // Start observing the target node
    }
}

export default GridResizer;

// const gridResizer = new GridResizer(options, imagesLoadedLibrary, customResizeHandler);

// // Attach multiple grid elements
// gridResizer.attach(gridElement1);
// gridResizer.attach(gridElement2);
