class DOMChangeObserver {
    constructor() {
        this.callbacks = []; // Array to store callback objects with associated nodes
        this.observer = null; // MutationObserver instance
        this.rootNode = null;
        this.isProcessing = false; // Flag to prevent recursion
    }

    /**
     * Initializes the MutationObserver for the given target node.
     * @param {Node} rootNode - The DOM node to observe (e.g., document.body or a specific container).
     */
    init(rootNode) {
        // Options for the observer
        const config = {
            childList: true, // Observe direct children
            subtree: true,   // Observe all descendants
            attributes: false, // Observe attribute changes
            characterData: false // Observe changes to text nodes
        };

        // Create an instance of MutationObserver and pass the mutation handler
        this.observer = new MutationObserver(this.mutationHandler.bind(this));

        // Start observing the target node for configured mutations
        this.observer.observe(rootNode, config);
        this.rootNode = rootNode;
    }

    /**
     * Attaches a new callback function to be executed on mutations.
     * @param {Function} callback - The function to be called on mutations.
     */
    attachCallback(callback, firstRun = false) {
        if (typeof callback === 'function') {
            this.callbacks.push(callback); // Store the callback
        } else {
            console.warn('Callback must be a function.');
        }
        if (firstRun) {
            callback(this.rootNode);
        }
    }

    /**
     * Handles mutations detected by the observer.
     * Executes all attached callbacks when new child nodes are added.
     * @param {MutationRecord[]} mutationsList - List of mutations that occurred.
     */
    mutationHandler(mutationsList) {
        if (this.isProcessing) {
            return; // Ignore mutations during processing
        }
    
        this.isProcessing = true;
    
        try {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    console.log('New content has been added.');
    
                    mutation.addedNodes.forEach(node => {
                        if (node.nodeType === Node.ELEMENT_NODE) {
                            this.callbacks.forEach(callback => {
                                callback(node);
                            });
                        }
                    });
                }
            }
        } finally {
            this.isProcessing = false; // Reset the flag
        }
    }

    // mutationHandler(mutationsList) {
    //     for (let mutation of mutationsList) {
    //         if (mutation.type === 'childList') {
    //             console.log('New content has been added.');

    //             // Call attached callbacks for each added node
    //             mutation.addedNodes.forEach(node => {
    //                 if (node.nodeType === Node.ELEMENT_NODE) {
    //                     this.callbacks.forEach(callback => {
    //                         callback(node); // Call the callback for each added node
    //                     });
    //                 }
    //             });
    //         }
    //     }
    // }

    /**
     * Stops observing the target node for changes.
     * Call this method when you no longer need to monitor mutations.
     */
    disconnect() {
        if (this.observer) {
            this.observer.disconnect(); // Stop the observer
        }
    }
}

export default DOMChangeObserver;

// // Example function to initialize Tippy on new tooltips
// function initializeTooltips(node) {
//     const tooltips = node.querySelectorAll('.tooltip');

//     tooltips.forEach(tooltip => {
//         tippy(tooltip, {
//             content: tooltip.getAttribute('data-tippy-content'),
//             // Add other Tippy options as needed
//         });
//     });
// }

// // Usage example
// const observer = new DOMChangeObserver();
// observer.init(document.body); // or another target node
// observer.attachCallback(initializeTooltips);
