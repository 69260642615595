/* DynamicFormHandler.js */
class DynamicFormHandler {

    constructor(geoData) {
        this.italy = 11;
        this.type = null;
        this.country = null;
        this.province = null;
        this.category = null;
        this.vat = null;
        this.city = null;
        this.taxCode = null;
        this.geoData = geoData;
    }

    async init(form) {
        this.form = form;
        this.type = form.getAttribute('data-profile');
        this.country = form.querySelector('#country');
        this.province = form.querySelector('#province');
        this.category = form.querySelector('#category');
        this.vat = form.querySelector('#vat');
        this.city = form.querySelector('#city');
        this.taxCode = form.querySelector('#taxCode');

        const url = `${window.location.protocol}//${window.location.host}/it/it/service/include/geodata`;
        this.geoData.loadData(url); 
        this.showAndRequireFields();
        this.form.addEventListener('change', (event) => this.handleChange(event));
    }

    showAndRequireFields() {
        const isItaly = this.country.value == this.italy;
        if (this.type === 'company') {
            this.toggleFields([this.province, this.vat, this.taxCode], isItaly);
            this.toggleRequiredFields([this.vat, this.province], isItaly);
            this.toggleRequiredFields([this.city], true);
        } else {
            this.toggleFields([this.province], isItaly);
            this.toggleRequiredFields([this.province], isItaly);
            this.toggleFields([this.city], !isItaly);
            this.toggleRequiredFields([this.city], !isItaly);
        }
    }

    toggleFields(elements, condition) {
        elements.forEach(element => {
            if (element) {
                const parent = this.findParentOfLabelSibling(element);
                if (parent) {
                    if (condition) {
                        parent.removeAttribute('hidden');
                    } else {
                        element.value = '';
                        parent.setAttribute('hidden', '');
                    }
                }
            }
        });
    }

    toggleRequiredFields(elements, condition) {
        elements.forEach(element => {
            if (element) {
                const parent = element.closest('.form-group');
                if (parent) {
                    parent.classList.toggle('required', condition);
                }
            }
        });
    }

    handleChange(event) {
        event.stopPropagation();
        const trigger = event.target.closest('select');
        if (trigger && trigger.matches('[name="country"]')) {
            if (this.province) {
                this.geoData.loadProvincesByCountry(this.province, this.country.value);
            }
            if (this.type === 'company') {
                this.fetchUserCategories(this.category, { country: this.country.value });
            }
            this.showAndRequireFields();
        }
    }

    async fetchUserCategories(element, options) {
        if (!element || element.tagName !== 'SELECT') return;

        const defaults = {
            jsonUrl: '/it/it/user/profile/categories',
            country: null
        };
        const config = { ...defaults, ...options };
        element.innerHTML = '';

        const url = `${config.jsonUrl}?country=${config.country}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin',
                redirect: 'follow',
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            const data = await response.json();
            this.populateCategories(element, data.categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }

    populateCategories(element, categories) {
        if (categories.length > 0) {
            element.appendChild(document.createElement('OPTION')); // Add a default empty option
            categories.forEach(category => {
                const option = document.createElement('OPTION');
                option.value = category.id;
                option.label = category.name;
                option.textContent = category.name;
                element.appendChild(option);
            });
        }
    }

    findParentOfLabelSibling(element) {
        let currentElement = element;
        while (currentElement) {
            const hasLabelSibling = Array.from(currentElement.parentNode.children).some(sibling =>
                sibling.tagName.toLowerCase() === 'label' && sibling !== currentElement
            );
            if (hasLabelSibling) {
                return currentElement.parentNode; // Return the parent if it has a label sibling
            }
            currentElement = currentElement.parentNode; // Climb up the DOM
        }
        return null; // Return null if no such parent is found
    }
}

export default DynamicFormHandler;

// const geoData = new GeoData(); // Assuming you have a GeoData class for geolocation
// const formHandler = new DynamicFormHandler(geoData);
// formHandler.init(document.querySelector('#myForm'));