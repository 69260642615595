// FetchJson.js
class FetchJson {
    /**
     * @param {Object} waiting - An instance of the Waiting service
     */
    constructor(waiting) {
        this.waiting = waiting; // Injected instance of Waiting
    }

    /**
     * 
     * @param {string} url 
     * @param {Object|Function} options 
     * @param {Function} callback 
     * @returns 
     */
    async fetch(url, options, callback) {
        if (url === null) {
            return;
        }

        let fetchOptions = {
            method: 'GET',
            cache: 'no-cache',
            mode: 'same-origin',
            credentials: 'same-origin',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        if (options) {
            if (typeof options === 'function') {
                fetchOptions = options(fetchOptions);
            } else if (typeof options === 'object') {
                Object.assign(fetchOptions, options);
            }
        }

        this.waiting.wait(true); // Use the injected Waiting instance

        try {
            const response = await fetch(url, fetchOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok: ' + response.statusText);
            }
            const json = await response.json();

            this.waiting.wait(false); // Use the injected Waiting instance
            if (json.success === undefined) {
                throw new Error('Variable success or data is not defined in response');
            }

            if (callback && typeof callback === 'function') {
                callback(json);
            }

            return json;

        } catch (error) {
            this.waiting.wait(false); // Use the injected Waiting instance
            console.log(error);
        }
    }
}

// Exporting the FetchJson class
export default FetchJson;
