/**
 * Resize handler that allows multiple functions to be executed after resizing the window.
 * Each callback can have its own timeout and optional event emitter.
 * 
 * Usage:
 *  const resizeHandler = new ResizeHandler();
 *  resizeHandler.addCallback(() => console.log("Resized!"), 500, eventEmitter);
 */
class ResizeHandler {
    constructor() {
        // Array to store registered callbacks
        this.callbacks = [];
        // Array to store timeout IDs for clearing them later
        this.timeoutIds = [];
        
        // Bind the debounced resize method to maintain correct context
        this.debouncedResize = this.debouncedResize.bind(this);
        
        // Add the debounced resize method as an event listener for the window's resize event
        window.addEventListener('resize', this.debouncedResize);
    }

    /**
     * Adds a callback to be triggered after a resize event.
     * 
     * @param {Function} callback - The function to be executed after the timeout.
     * @param {number} timeout - The debounce timeout for this callback, in milliseconds.
     * @param {Object} [eventEmitter] - Optional event emitter for this callback, if needed.
     */
    addCallback(callback, timeout, eventEmitter) {
        // Ensure the callback is a function
        if (typeof callback !== 'function') {
            throw new Error('Callback must be a function');
        }
        // Ensure the timeout is a positive number
        if (typeof timeout !== 'number' || timeout <= 0) {
            throw new Error('Timeout must be a positive number');
        }

        // Store the callback and its settings for later execution
        this.callbacks.push({ callback, timeout, eventEmitter });
    }

    /**
     * Debounced resize method that gets called on window resize events.
     * This method triggers the registered callbacks after the specified timeout.
     */
    debouncedResize() {
        // Clear any existing timeouts to avoid duplicate executions
        this.timeoutIds.forEach(clearTimeout);
        this.timeoutIds = []; // Reset the timeout IDs array

        // Set timeouts for each registered callback
        this.callbacks.forEach(({ callback, timeout, eventEmitter }) => {
            const timeoutId = setTimeout(() => {
                // Emit an event if using an event emitter
                if (eventEmitter) {
                    eventEmitter.emit('resize');
                }
                // Execute the callback function
                callback();
            }, timeout);
            // Store the timeout ID to allow for clearing if needed
            this.timeoutIds.push(timeoutId);
        });
    }

    /**
     * Cleans up the event listener and any remaining timeouts.
     */
    destroy() {
        // Remove the resize event listener from the window
        window.removeEventListener('resize', this.debouncedResize);
        // Clear any remaining timeouts to prevent unwanted executions
        this.timeoutIds.forEach(clearTimeout);
    }
}

export default ResizeHandler;