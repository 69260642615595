/* TemplateEngine.js */
class TemplateEngine {

    /**
     * @param {TemplateCollection} templateCollection 
     */
    constructor(templateCollection) {
        this.templateCollection = templateCollection;
    }

    getTemplateCollection() {
        return this.templateCollection;
    }

    render(templateName, options = {}) {
        const html = this.templateCollection.get(templateName);
        const re = /<%(.+?)%>/g;
        const reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g;
        let code = 'with(obj) { var r=[];\n';
        let cursor = 0;
        let result;
        let match;
    
        const add = (line, js) => {
            if (js) {
                code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n';
            } else {
                code += line !== '' ? 'r.push(`' + line.replace(/`/g, '\\`').replace(/\\/g, '\\\\') + '`);\n' : '';
            }
            return add;
        };
    
        while (match = re.exec(html)) {
            add(html.slice(cursor, match.index))(match[1], true);
            cursor = match.index + match[0].length;
        }
        add(html.substr(cursor, html.length - cursor));
        code += 'return r.join(""); }'.replace(/[\r\t\n]/g, ' ');
    
        try {
            result = new Function('obj', code).apply(options, [options]);
        } catch (error) {
            console.error(`'${error.message}' in \n\nCode:\n`, code);
        }
    
        return result;
    }

    /**
     * Renders the template and returns it as a DOM element
     * @param {string} templateName 
     * @param {Object} options 
     * @returns {HTMLElement}
     */
    renderToElement(templateName, options) {
        const htmlString = this.render(templateName, options);
        const template = document.createElement('div');
        template.innerHTML = htmlString.trim();  // Wrap the HTML string in a div to convert to DOM element
        return template.firstElementChild;  // Return the first child (the rendered element)
    }    
}

export default TemplateEngine;

