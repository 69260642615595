import "../scss/ScrollHandler.scss";

class ScrollHandler {

  constructor() {
    this.button = null;
    this.sentinel = null;
    this.observer = null;
    this.scrollUpIconHTML = '';
    this.scrollDownIconHTML = '';
  }

  // Initialize the ScrollHandler with the button, sentinel elements, and HTML for both icons
  initialize(scrollUpIconHTML = '', scrollDownIconHTML = '') {

    console.log('ScrollHandler initialization');
    this.button = document.querySelector('[data-scroll-button]');
    this.sentinel = document.querySelector('[data-scroll-sentinel]');

    // If either the button or sentinel is not found, do not proceed with instantiation
    if (!this.button || !this.sentinel) return;

    // Set the initial icon HTML for both scroll up and scroll down if provided
    this.scrollUpIconHTML   = scrollUpIconHTML;
    this.scrollDownIconHTML = scrollDownIconHTML;

    this._initObserver(); // Set up IntersectionObserver
    this.button.addEventListener("click", () => this._handleButtonClick());

    this._updateButtonIcon('up'); // Default to scroll up
  }

  // Helper function to get DOM element by selector or return the element itself
  _getElement(element) {
    return typeof element === "string" ? document.querySelector(element) : element;
  }

  // Initialize the intersection observer
  _initObserver() {
    if (this.observer) return; // Only initialize once

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const direction = entry.isIntersecting ? 'down' : 'up';
        this._updateButtonIcon(direction);
      });
    }, { threshold: 0.1 });

    this.observer.observe(this.sentinel);
  }

  // Update the button's icon, background color, and click action based on direction
  _updateButtonIcon(direction) {
    const iconHTML = direction === 'down' ? this.scrollDownIconHTML : this.scrollUpIconHTML;
    const title = direction === 'down' ? 'Scroll Down' : 'Scroll Up';
    // const color = direction === 'down' ? '#28a745' : '#007bff';
    const action = direction === 'down' ? this._scrollDown : this._scrollToTop;

    this.button.title = title;
    // this.button.style.backgroundColor = color;
    this.button.dataset.scrollButton = direction;

    // Set the button's icon HTML
    if (this.button.innerHTML !== iconHTML) {
      this.button.innerHTML = iconHTML;
    }

    // Remove previous event listeners and set up the correct one
    this.button.removeEventListener("click", this._scrollToTop);
    this.button.removeEventListener("click", this._scrollDown);
    this.button.addEventListener("click", action.bind(this));
  }

  // Handle the button click for scrolling based on current scroll direction
  _handleButtonClick() {
    const action = this.button.dataset.scrollButton === 'down' ? this._scrollDown : this._scrollToTop;
    action.call(this);
  }

  // Scroll to top of the page
  _scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // Scroll down to the next section (next viewport)
  _scrollDown() {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
  }
}

export default ScrollHandler;


// const scrollHandler = new ScrollHandler();
      
// // Using selectors (string-based initialization) with custom icons for scroll up and scroll down
// scrollHandler.initialize('.scroll-btn', '.spacer', 
//   '<i class="fas fa-chevron-up"></i>',  // Scroll up icon
//   '<i class="fas fa-chevron-down"></i>' // Scroll down icon
// );

// Or using actual DOM elements (direct reference)
// const button = document.querySelector('.scroll-btn');
// const sentinel = document.querySelector('.spacer');
// scrollHandler.initialize(button, sentinel, 
//   '<i class="fas fa-chevron-up"></i>',
//   '<i class="fas fa-chevron-down"></i>'
// );