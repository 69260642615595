class ModalManager {
    static SELECTORS = {
        MODAL_URL:  '[data-modal="url"][data-modal-id][data-modal-source]',
        MODAL_PAGE: '[data-modal="page"][data-modal-id][data-modal-source]',
    };

    constructor(templateEngine, fetchHtml, waiting) {
        this.templateEngine = templateEngine;
        this.fetchHtml = fetchHtml;
        this.waiting = waiting;

        // Bind methods to the class context
        this.handleEvent = this.handleEvent.bind(this);
    }

    /**
     * Observe an element for click events to trigger modals.
     * @param {HTMLElement} element
     */
    observe(element) {
        element.addEventListener('click', this.handleEvent);
    }

    /**
     * Stop observing an element for click events.
     * @param {HTMLElement} element
     */
    unobserve(element) {
        element.removeEventListener('click', this.handleEvent);
    }

    /**
     * Handle click events for modal triggers.
     * @param {Event} event - The click event.
     */
    handleEvent(event) {
        const triggers = [
            { selector: ModalManager.SELECTORS.MODAL_URL, handler: this.handleModalUrl },
            { selector: ModalManager.SELECTORS.MODAL_PAGE, handler: this.handleModalPage },
        ];

        for (const { selector, handler } of triggers) {
            const trigger = event.target.closest(selector);
            if (trigger) {
                event.preventDefault();
                handler.call(this, trigger);
                break;
            }
        }
    }

    /**
     * Handle modals triggered via URL.
     * @param {HTMLElement} trigger - The clicked element.
     */
    handleModalUrl(trigger) {
        const node = this.createModal(trigger);
        const source = trigger.getAttribute('data-modal-source');
        const reload = trigger.getAttribute('data-modal-reload');
        this.cleanupModal(node, reload);
        this.loadContent(source, node);
    }

    /**
     * Handle modals triggered via page content (to be implemented).
     * @param {HTMLElement} trigger - The clicked element.
     */
    handleModalPage(trigger) {
        const source = trigger.getAttribute('data-modal-source');
        const reload = trigger.getAttribute('data-modal-reload');
        /** @type {HTMLElement} */
        const element = document.querySelector(source);

        const node = this.createModal(trigger);
        this.cleanupModal(node, reload);
        const modalBody = node.querySelector('[data-modal-section="body"]');
        modalBody.innerHTML = element.innerHTML;
    }

    createModal(element) {
        const id = element.getAttribute('data-modal-id');
        const title = element.getAttribute('data-modal-title') || 'Info';
        const size = element.getAttribute('data-modal-size') || 'modal-lg';

        // Create and display the modal
        const node = this.templateEngine.renderToElement('modal', {
            id:    id,
            title: title,
            body: 'Loading...',
            size:  size,
        });
        document.body.appendChild(node);
        const modal = new bootstrap.Modal(node);
        modal.show();
        return node;
    }

    /**
     * Load content into the modal and handle forms if present.
     * @param {string} url - The URL to fetch content from.
     * @param {HTMLElement} node - The modal DOM element.
     */
    async loadContent(url, node) {
        console.log(`Loading content from URL: ${url}`);
        const modalBody = node.querySelector('[data-modal-section="body"]');
        try {
            // Fetch the HTML content and update the modal body
            await this.fetchHtml.fetch(url, modalBody);

            // Check if there's a form to handle
            const forms = modalBody.querySelectorAll('form');
            if (forms.length === 1) {
                this.handleForm(forms[0], node);
            }
        } catch (error) {
            console.error(`Error fetching modal content from ${url}:`, error);
            modalBody.textContent = `Error loading content: ${error.message}`;
        }
    }

    /**
     * Handle a form within the modal.
     * @param {HTMLFormElement} form - The form element.
     * @param {HTMLElement} node - The modal DOM element.
     */
    handleForm(form, node) {
        const submitButton = form.querySelector('button[type="submit"]');
        const modalFooter = node.querySelector('[data-modal-section="footer"]');

        if (!form.hasAttribute('id')) {
            console.error('The form must have an ID.');
            return;
        }

        if (submitButton) {
            modalFooter.appendChild(submitButton);

            submitButton.addEventListener('click', async (event) => {
                event.preventDefault();

                if (submitButton.disabled) return; // Prevent double submission
                submitButton.disabled = true;

                this.waiting.wait(true);

                try {
                    const response = await fetch(form.getAttribute('action'), {
                        method: form.getAttribute('method'),
                        body: new FormData(form),
                        headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    });

                    if (!response.ok) {
                        throw new Error(`Request failed: ${response.statusText}`);
                    }

                    const html = await response.text();
                    const modalBody = node.querySelector('[data-modal-section="body"]');
                    modalBody.innerHTML = html;

                    this.executeScripts(modalBody);
                } catch (error) {
                    console.error('Form submission error:', error);
                } finally {
                    submitButton.disabled = false;
                    this.waiting.wait(false);
                }
            });
        }
    }

    /**
     * Execute any <script> tags found in the modal content.
     * @param {HTMLElement} container - The container with the content.
     */
    executeScripts(container) {
        const scripts = container.querySelectorAll('script');

        scripts.forEach((script) => {
            const newScript = document.createElement('script');
            
            // If the script has a `src`, reload it from the source
            if (script.src) {
                newScript.src = script.src;
                newScript.async = script.async; // Preserve async attribute
            } else {
                newScript.textContent = script.textContent;
            }
            
            // Preserve type if it is valid for execution
            if (script.type && script.type !== 'text/javascript') {
                newScript.type = script.type;
            }
            
            document.head.appendChild(newScript);
            newScript.remove(); // Clean up after execution
        });
    }

    /**
     * Cleanup the modal by removing it from the DOM when hidden.
     * @param {HTMLElement} node - The modal DOM element.
     * @param {String} reloadOnClose - Whether to reload the page on close.
     */
    cleanupModal(node, reloadOnClose = 'true') {
        node.addEventListener('hidden.bs.modal', () => {
            node.remove();
            if (reloadOnClose === 'true') {
                document.location.reload();
            }
        });
    }
}

export default ModalManager;
