class Waiting {
    constructor() {
        if (Waiting.instance) {
            return Waiting.instance;
        }

        // Create styles for the spinner and animations
        this.createStyles();
        this.wrapper = this.createWrapper();

        // Store the instance
        Waiting.instance = this;
    }

    createStyles() {
        const style = document.createElement('style');
        style.innerHTML = `
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `;
        document.head.appendChild(style);
    }

    createWrapper() {
        const wrapper = document.createElement('div');
        wrapper.id = 'waiting-wrapper';
        wrapper.style.zIndex = 1100;
        wrapper.style.position = 'fixed';
        wrapper.style.top = 0;
        wrapper.style.right = 0;
        wrapper.style.bottom = 0;
        wrapper.style.left = 0;
        wrapper.style.backgroundColor = 'transparent';
        
        const element = document.createElement('div');
        element.id = 'waiting';
        element.style.position = 'fixed';
        element.style.top = '50%';
        element.style.left = '50%';
        element.style.transform = 'translate(-50%, -50%)';
        element.style.zIndex = 1100;

        element.innerHTML = `
            <div style="
                width: 120px;
                height: 120px;
                border-top: 8px solid #ffa200;
                border-right: 8px solid transparent;
                border-radius: 50%;
                animation: spin 1.8s linear infinite;
            "></div>
        `;
        
        wrapper.appendChild(element);
        return wrapper;
    }

    wait(status) {
        if (status && !this.wrapper.parentNode) {
            document.body.appendChild(this.wrapper);
        } else if (!status && this.wrapper.parentNode === document.body) {
            document.body.removeChild(this.wrapper);
        }
    }
}

export default Waiting;

// // Usage example:
// const singleA = new Waiting();
// singleA.wait(true);

// const singleB = new Waiting();
// console.log(singleA === singleB); // true
