/* BrowserDetector.js */

class BrowserDetector {
    constructor() {
        this.ua = navigator.userAgent;
        this.browserInfo = this.detectBrowser();
    }

    detectBrowser() {
        const ua = this.ua;
        const match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        let name = match[1];
        let version = match[2] || "";

        if (/trident/i.test(name)) {
            const rvMatch = /\brv[ :]+(\d+)/g.exec(ua);
            return { name: "IE", version: rvMatch ? rvMatch[1] : "" };
        }

        if (name === "Chrome") {
            const edgeMatch = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (edgeMatch) {
                name = edgeMatch[1].replace("OPR", "Opera");
                version = edgeMatch[2];
            }
        }

        const versionMatch = ua.match(/version\/(\d+)/i);
        if (versionMatch) {
            version = versionMatch[1];
        }

        return { name: name || navigator.appName, version };
    }

    getBrowserInfo() {
        return this.browserInfo;
    }

    isOutdated(name, version) {
        const versionNum = parseInt(version, 10);
        return (
            (name === 'Chrome' && versionNum < 60) ||
            (name === 'Firefox' && versionNum < 60) ||
            (name === 'Edge' && versionNum < 79) ||
            (name === 'IE' && versionNum <= 11) ||
            (name === 'Opera' && versionNum < 32) ||
            (name === 'Safari' && versionNum < 11)
        );
    }
}

export default BrowserDetector;


// import BrowserDetector from './BrowserDetector.js';

// const detector = new BrowserDetector();
// const browserInfo = detector.getBrowserInfo();
// console.log(`Browser: ${browserInfo.name}, Version: ${browserInfo.version}`);
