/* DIContainer.js */
class DIContainer {
    constructor() {
        if (DIContainer.instance) {
            return DIContainer.instance; // Ensure a single instance
        }
        this.services = new Map();
        this.factories = new Map();
        DIContainer.instance = this;
    }

    register(name, instance, isSingleton = false) {
        this.services.set(name, { instance, isSingleton });
    }

    registerFactory(name, factory, isSingleton = false) {
        this.factories.set(name, { factory, isSingleton });
    }

    resolve(name, ...args) {
        // Check if service is registered as singleton
        if (this.services.has(name)) {
            const { instance, isSingleton } = this.services.get(name);
            if (isSingleton) {
                return instance;
            } else {
                return typeof instance === 'function' ? new instance(...args) : instance;
            }
        }

        // If registered as a factory
        if (this.factories.has(name)) {
            const { factory, isSingleton } = this.factories.get(name);
            const instance = factory(this, ...args);  // Pass args to factory function
            
            // If factory instance is a singleton, register it permanently
            if (isSingleton) {
                this.register(name, instance, true);
            }
            return instance;
        }

        throw new Error(`Service ${name} not found`);
    }
}

export default DIContainer;
