class DragNDrop {

    /**
     * Constructor for the DragNDrop class.
     *
     * @param {string} contents - Selector for draggable elements.
     * @param {string} draggers - Selector for elements triggering the drag.
     * @param {string} movingCss - CSS string for the moving icon and style.
     */
    constructor(contents, draggers, movingCss = `
            .moving{
                padding-left:2em;
            }
            .moving::before{
                margin-left:-1.1em;
                position:absolute;
                font-size:1.5em;
                font-family:"Font Awesome 6 Free";
                font-weight:400;content:"\\f35a";
                transform:rotate(-40deg);
            }`) {
        this.contents = contents;
        this.draggers = draggers;
        this.dragSource = null;
        this.dropCallback = null;
        this.movingCss = movingCss;

        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

        this.addStyles();
    }

    /**
     * Adds CSS styles for the drag-and-drop effect.
     */
    addStyles() {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = this.movingCss;
        document.head.appendChild(style);
    }

    /**
     * Event handler for the dragstart event.
     */
    handleDragStart(event) {
        event.stopPropagation();
        this.dragSource = event.target;
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/plain', 'node'); // Dummy data (required for Firefox)
        // event.dataTransfer.setData('text', event.target.innerHTML);
    }

    /**
     * Event handler for the dragover event.
     */
    handleDragOver(event) {
        event.preventDefault(); // Necessary for allowing drop.
        event.stopPropagation();
        event.target.closest(this.draggers).classList.add('moving');
        event.dataTransfer.dropEffect = 'move';
    }

    /**
     * Event handler for the dragleave event.
     */
    handleDragLeave(event) {
        event.target.closest(this.draggers).classList.remove('moving');
    }

    /**
     * Event handler for the drop event.
     */
    handleDrop(event) {
        event.stopPropagation(); // Stops event bubbling
        event.preventDefault();  // Prevents default browser behavior (e.g., redirecting)
    
        // Remove visual drag effect
        event.target.closest(this.draggers).classList.remove('moving');
    
        /** @type {HTMLElement} */
        const draggedContent = this.dragSource.closest(this.contents);
    
        /** @type {HTMLElement} */
        const targetContent = event.target.closest(this.contents);
    
        // Ensure the dragged and target elements are not the same
        if (targetContent && targetContent !== draggedContent) {
            console.log(targetContent);
            console.log(draggedContent);
    
            // Move `draggedContent` before `targetContent`
            targetContent.parentNode.insertBefore(draggedContent, targetContent);
    
            // Execute the drop callback if provided
            if (this.dropCallback) {
                this.dropCallback(draggedContent.parentNode);
            }
        }
    }
    
    /**
     * Initializes the DragNDrop functionality for specified elements.
     *
     * @param {function} dropCallback - Callback function for the drop event.
     */
    init(dropCallback) {
        this.dropCallback = dropCallback;
        const elements = document.querySelectorAll(this.contents);

        elements.forEach((element) => {
            const triggers = element.parentNode.querySelectorAll(this.draggers);

            triggers.forEach((trigger) => {
                trigger.setAttribute('draggable', true);
            });

            element.addEventListener('dragstart', this.handleDragStart, false);
            element.addEventListener('dragover', this.handleDragOver, false);
            element.addEventListener('dragleave', this.handleDragLeave, false);
            element.addEventListener('drop', this.handleDrop, false);
        });
    }
}

export default DragNDrop;



// import DragNDrop from './DragNDrop';
// import ArticleEditDrag from './ArticleEditDrag';

// // DI container setup
// const container = new Map();
// container.set('dragNDrop', () => new DragNDrop());
// container.set('articleEditDrag', (c) => new ArticleEditDrag(c.get('dragNDrop')));

// // Resolve and initialize
// const articleEditDrag = container.get('articleEditDrag')(container);
// articleEditDrag.init();

