class ArticleEditDrag {
    /**
     * Constructor for the ArticleEditDrag class.
     *
     * @param {DragNDrop} dragNDropInstance - Instance of the DragNDrop class.
     */
    constructor(dragNDropInstance) {
        if (!dragNDropInstance) {
            throw new Error('DragNDrop instance is required');
        }
        this.dragNDrop = dragNDropInstance;
    }

    /**
     * Defines the callback to execute when an item is dropped.
     *
     * @param {HTMLElement} parent - The parent element where the item is dropped.
     */
    async dropCallback(parent) {
        console.log(`Repositioning nodes of ${parent}`);
        const type = parent.getAttribute('data-type');
        if (!type) return;
    
        // Gather draggable elements and their rankings
        let requestData = {
            type,
            items: [],
        };
    
        let rank = 0;
        const groups = document.querySelectorAll(`[data-type="${type}"]`);
        groups.forEach((group) => {
            console.log(group);
            [...group.children].forEach((element) => {
                const isDraggable = element.matches(`${this.dragNDrop.contents}[data-object-id]`);
                requestData.items.push({
                    id: element.getAttribute('data-object-id'),
                    rank: isDraggable ? ++rank : null,
                });
            });
        });
    
        // Send data to the server
        try {
            const response = await fetch('/it/it/article/admin-resource/dragndrop-persist', {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'same-origin',
                redirect: 'follow',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', // Set the content type for JSON
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(requestData), // Send JSON data in the body
            });
    
            if (!response.ok) {
                throw new Error(`Server error: ${response.statusText}`);
            }
    
            const responseData = await response.json();
            console.log('Update successful:', responseData);
        } catch (error) {
            console.error('Error updating rankings:', error);
            alert(`Error: ${error.message}`);
        }
    }
    
    /**
     * Initialize the drag-and-drop functionality.
     *
     * Sets up DragNDrop for specific selectors.
     */
    init() {
        this.dragNDrop.init((parent) => this.dropCallback(parent));
    }
}

export default ArticleEditDrag;


// import DragNDrop from './DragNDrop';
// import ArticleEditDrag from './ArticleEditDrag';

// // Simple DI container
// const container = new Map();

// // Register dependencies
// container.set('dragNDrop', () => new DragNDrop());
// container.set('articleEditDrag', (c) => new ArticleEditDrag(c.get('dragNDrop')));

// // Resolve dependencies and initialize
// const articleEditDrag = container.get('articleEditDrag')(container);
// articleEditDrag.init();
