/* JsCombo.js */

/**
 * Class that builds dependent dropdowns (select elements) from a jstree structure.
 * 
 * This class is used to transform a jstree structure into a series of cascading combo boxes.
 * Each dropdown is built based on the nested `ul` and `li` elements within the jstree. 
 * Each `li` element in the jstree should have a `data-jstree` attribute containing a JSON object 
 * with at least the `code` and `icon` properties to be used for the dropdown options.
 * 
 * Example:
 * - The first dropdown will show all root-level items in the jstree.
 * - When an option is selected, the next dropdown is populated based on the child elements of the selected `li`.
 * 
 * @class JsCombo
 */
class JsCombo {
  /**
   * Creates an instance of the JsCombo class.
   * The constructor is kept empty for compatibility with Dependency Injection (DI) containers.
   * Use the `initialize` method to configure the instance.
   * 
   * @constructor
   */
  constructor() {
    this.jstree = null;      // The jstree DOM element that contains the hierarchical data.
    this.name = '';          // The name attribute for the select elements.
    this.active = '';        // The ID of the currently active node, used for pre-selecting the dropdown.
    this.baseurl = '';       // The base URL for the form's action attribute.
    this.prefix = 'lf-nd-';  // The prefix used to identify nodes in the jstree structure.
    this.form = null;        // The form element that will hold the select dropdowns and submit button.
    this.layout = null;      // The row of the layout where to insert everything
  }

  /**
   * Initializes the `JsCombo` instance with the required configuration.
   * This method is typically used after the class is instantiated, especially in DI systems.
   *
   * @param {HTMLElement} jstree - The jstree DOM element containing the hierarchical structure.
   * @param {string} name - The name attribute for the select elements in the form.
   * @param {string} active - The ID of the active node to pre-select in the dropdowns.
   * @param {string} baseurl - The base URL for the form's action attribute.
   * @param {string} prefix - The prefix used to identify nodes in the jstree structure.
   */
  initialize(jstree, name, active = '', baseurl = '', prefix = 'lf-nd-') {
    this.jstree = jstree;
    this.name = name;
    this.active = active;
    this.baseurl = baseurl;
    this.prefix = prefix;

    // Initialize the form element.
    this.form = document.createElement('form');
    this.form.classList.add('jscombo', 'form-inline');
    this.form.action = this.baseurl;

    // Create the group div for styling purposes
    this.layout = document.createElement('div');
    this.layout.classList.add('row', 'g-1');
    this.layout.setAttribute('data-jscombo', 'row');
    this.form.appendChild(this.layout);  // Add the select element to the group div
  }

  /**
   * Builds a dropdown (select) element from the <ul> and its child <li> elements.
   * Each <li> element must have a `data-jstree` attribute containing a JSON string with `code` and optionally `icon`.
   *
   * @param {HTMLElement} ul - The <ul> element containing <li> elements that define the options for the dropdown.
   * @param {string} selected - The value to be selected in the dropdown (optional).
   * @returns {HTMLElement} - The constructed dropdown element wrapped in a <div>.
   */
  buildDropDown(ul, selected) {
    // // Create the group div for styling purposes
    const col = document.createElement('div');
    col.classList.add('col-auto');
    col.setAttribute('data-jscombo', 'col');

    // Create the select element and set its class
    const select = document.createElement('select');
    select.classList.add('form-select');
    select.innerHTML = '<option/>'; // Empty first option

    // Iterate over the child <li> elements of the <ul>
    [...ul.children].forEach((li) => {
      const option = document.createElement('option');
      const link = li.querySelector('a');  // Get the <a> tag inside the <li>
      const jsonData = li.getAttribute('data-jstree');  // Get the JSON data from the li element

      if (jsonData) {
        // Parse the JSON data to get the code and icon properties
        const { code, icon } = JSON.parse(jsonData);
        option.value = code;  // Set the value of the option to the 'code'
        option.innerHTML = link.innerHTML;  // Set the inner HTML to the text of the <a> element

        if (icon) {
          // If an icon is provided, add it to the option's data-content attribute for rendering
          option.setAttribute('data-content', `<img src="${icon}"> ${link.innerHTML}`);
        }

        if (selected && code === selected) {
          option.selected = true;  // Pre-select the option if it matches the selected value
        }

        select.appendChild(option);  // Append the option to the select dropdown
      }
    });

    col.appendChild(select);
    return col;  // Return the col containing the select element
  }

  /**
   * Renders the cascading dropdowns based on the jstree structure and the active node.
   * This method will create a form with the necessary selects, dynamically populate them, and insert the form 
   * before the jstree element in the DOM.
   */
  render() {
    const { active, jstree, name, prefix } = this;

    if (active) {
      // Find the active node based on its ID
      const li = jstree.querySelector(`li[id="${prefix}${active}"]`);
      const candidates = [];
      let elem = li;
      while (elem !== jstree) {
        if (elem.tagName === 'LI') {
          candidates.push(elem);  // Add the parent LI nodes to the candidates array
        }
        elem = elem.parentNode;  // Move up the DOM tree
      }

      // Iterate over the parent nodes and create dropdowns
      for (let i = 0; i < candidates.length; i++) {
        const itemLi = candidates[i];
        const jsonData = itemLi.getAttribute('data-jstree');
        if (jsonData) {
          const { code } = JSON.parse(jsonData);
          const dropDown = this.buildDropDown(itemLi.parentNode, code);  // Create dropdown for this level

          if (i === 0) {
            // For the first dropdown, add the name attribute and create sub-dropdown if applicable
            dropDown.querySelector(':scope > select').setAttribute('name', name);
            const subNode = itemLi.querySelector(':scope > ul');
            if (subNode) {
              const subDropDown = this.buildDropDown(subNode, null);  // Add sub-dropdown
              this.layout.appendChild(subDropDown);
            }
          }

          this.layout.insertBefore(dropDown, this.layout.firstChild);  // Insert the dropdown before the previous ones
        }
      }
    } else {
      // If no active node is specified, just use the first <ul> to create the dropdown
      const subNode = jstree.querySelector(':scope > ul');
      if (subNode) {
        const dropDown = this.buildDropDown(subNode, null);  // Create the dropdown from the first <ul>
        this.layout.appendChild(dropDown);
      }
    }

    // Create the submit button and append it to the layout
    const submit = document.createElement('div');
    submit.classList.add('col-auto');
    const button = document.createElement('button');
    button.classList.add('btn', 'btn-dark');
    button.type = 'submit';
    button.innerHTML = '<i class="fa-solid fa-search"></i>';
    submit.appendChild(button);
    this.layout.appendChild(submit);

    // Insert the form before the jstree element in the DOM
    jstree.parentNode.insertBefore(this.form, jstree);

    // Trigger a window resize event to adjust layout if necessary
    window.dispatchEvent(new Event('resize'));

    // Add event listener for changes in the form's select elements
    this.layout.addEventListener('change', (e) => {
      const trigger = e.target.closest('select');
      if (!trigger) return;  // Exit if no select element is found

      e.preventDefault();
      e.stopImmediatePropagation();

      const triggerNode = trigger.closest('[data-jscombo="col"]');
      let nextSibling = triggerNode.nextElementSibling;

      // Remove any subsequent select elements except for the submit button
      while (nextSibling && nextSibling.getAttribute('data-jscombo') === 'col') {
        this.layout.removeChild(nextSibling);
        nextSibling = nextSibling.nextElementSibling;
      }

      // Set the name attribute only on the modified select element
      const activeSelect = triggerNode.querySelector('select');
      if (activeSelect) {
          activeSelect.setAttribute('name', name);
      }

      let siblings = [...triggerNode.parentNode.children].filter(sibling => sibling !== triggerNode);

      // Remove the name attribute from all other select elements to prevent sending multiple IDs
      siblings.forEach((colNode) => {
          const selectNode = colNode.querySelector('select');
          if (selectNode && selectNode.hasAttribute('name')) {
            selectNode.removeAttribute('name');
          }
      });

      // Fetch the selected value and create the next dropdown if necessary
      const value = triggerNode.querySelector('select').value;
      const elemLi = jstree.querySelector(`li[id="${prefix}${value}"]`);
      const subNode = elemLi.querySelector(':scope > ul');
      if (subNode) {
        const subDropDown = this.buildDropDown(subNode, null);  // Create sub-dropdown for child nodes
        this.layout.lastChild.parentNode.insertBefore(subDropDown, this.layout.lastChild);
      }
    });
  }
}


export default JsCombo;


// // Initialize the instance with the required parameters
// jscombo.initialize(jstreeElement, 'id to pass to form', 'activeNodeId', '/some/base/url', 'lf-nd-', (select) => {
//   console.log('Dropdown created:', select);
// });

// // Render the form
// jscombo.render();