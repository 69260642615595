/* CatalogApp.js */
class CatalogApp {
    constructor(catalog) {
        this.catalog = catalog;
        this.container = null;
        this.baseUrl = null;

        this.initEventListeners();
        this.setupAppMethods();
        this.init();
    }

    /**
     * Initialize event listeners
     */
    initEventListeners() {
        document.addEventListener('catalog-cart-update', (event) => {
            const target = `appvimar://native/updateCartNum:${event.detail.quantity}`;
            window.location.href = target;
        });
    }

    /**
     * Setup application methods on the vimar app
     */
    setupAppMethods() {
        window.vimar.app = window.vimar.app || {};

        window.vimar.app.runSearch = (value) => {
            // window.location.href = `${this.baseurl}/search?term=${value.rawurlencode()}`;
            const term = encodeURIComponent(String(value));
            window.location.href = `${this.baseurl}/search?term=${term}`;
        };

        window.vimar.app.dropCart = () => {
            if (this.catalog) {
                this.catalog.showCart();
            }
        };
    }

    init() {
        console.log('CatalogApp initialization');
        this.container = document.querySelector('[data-catalog-namespace="catalog"]');
        this.baseUrl = this.container.getAttribute('data-catalog-baseurl');
    }    
}

export default CatalogApp;