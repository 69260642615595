// ClipboardCopy class provides functionality for copying text to the clipboard
class ClipboardCopy {
    constructor() {
        // Optional: Initialize any additional configuration if needed
    }

    // Method to copy a given text to the clipboard
    // Accepts a 'text' parameter to specify the text to be copied
    copy(text) {
        // Uses the Clipboard API to write text to the user's clipboard
        return navigator.clipboard.writeText(text)
            .then(() => {
                // Display a success alert if the text is successfully copied
                alert("Text copied to clipboard!");
            })
            .catch((err) => {
                // Log an error to the console if the copy operation fails
                console.error("Failed to copy text:", err);
            });
    }

    // Method to attach a click listener to elements, passing custom text to copy
    // Parameters:
    //  - selector: a CSS selector to specify the elements to attach the listener to
    //  - getTextCallback: a callback function to dynamically retrieve text from a target element
    attach(selector, getTextCallback) {
        // Listen for 'click' events on the entire document
        document.addEventListener('click', (event) => {
            // Check if the clicked target matches the specified selector
            const target = event.target.closest(selector);
            // If target matches and getTextCallback is a function, execute callback to get the text
            if (target && typeof getTextCallback === 'function') {
                const text = getTextCallback(target); // Call callback to get the text
                // If valid text is returned, attempt to copy it
                if (text) this.copy(text);
            }
        });
    }
}

// Export the ClipboardCopy class to make it available for import in other modules
export default ClipboardCopy;

// const clipboardCopy = container.resolve('clipboardCopy');
// document.querySelectorAll('[data-clipboard-text]').forEach((element) => {
//     clipboardCopy.attach(element, (element) => {
//         return element.getAttribute('data-clipboard-text');
//     });
// });