/* TemplateCollection.js */

class TemplateCollection {
    constructor() {
        this.templates = {};
    }

    add(name, template) {
        this.templates[name] = template;
        return this;
    }

    get(name) {
        return this.templates[name] || '';
    }
}

export default TemplateCollection;
