import { LuminousGallery } from "luminous-lightbox";
import "luminous-lightbox/dist/luminous-basic.css";

// If no named export exists, use this
// import * as Gallery from "luminous-lightbox/es/gallery.js";

class LuminousManager {
  /**
   * Creates the LuminousManager instance.
   * @param {object} options - Configuration options for LuminousGallery.
   */
  constructor(options = {}) {
    this.options = options;
    this.galleryInstance = null;
  }

  /**
   * Initializes the gallery for the given elements or selector.
   * @param {string | NodeList | HTMLElement[]} elements - 
   *   A CSS selector string, NodeList, or array of HTMLElements to initialize the gallery.
   */
  gallery(elements) {
    let resolvedElements;

    // Handle different input types
    if (typeof elements === 'string') {
      resolvedElements = document.querySelectorAll(elements);
    } else if (elements instanceof NodeList || Array.isArray(elements)) {
      resolvedElements = elements;
    } else {
      console.warn('Invalid input: elements must be a selector string, NodeList, or array of HTMLElements.');
      return;
    }

    // Check if elements exist
    if (resolvedElements.length === 0) {
      console.log('No elements found to initialize the gallery.');
      return;
    }

    // Initialize LuminousGallery
    this.galleryInstance = new LuminousGallery(resolvedElements, this.options);
    console.log('LuminousGallery initialized:', this.galleryInstance);
  }

  /**
   * Destroys the gallery instance and performs cleanup.
   */
  destroy() {
    if (this.galleryInstance) {
      this.galleryInstance = null; // Additional cleanup logic can go here if required.
      console.log('LuminousGallery destroyed');
    } else {
      console.warn('No gallery instance to destroy.');
    }
  }
}

export default LuminousManager;
