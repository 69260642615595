/* Catalog.js */
class Catalog {
    constructor(waiting, numbro) {
        this.baseUrl   = null;
        this.container = null;
        this.timeout   = null;
        // this.modalOptions = {
        //     backdrop: 'static',
        //     show: true,
        //     keyboard: false
        // };
        this.waiting = waiting;
        this.numbro  = numbro;
        this.init();
    }

    // Utility function to execute a callback for each cart row
    eachCartRow(callback) {
        const nodeList = document.querySelectorAll('[data-catalog-namespace="catalog/cart/product"]');
        return Array.from(nodeList).map(currentNode => {
            const code = currentNode.getAttribute('data-catalog-code');
            const inputPrice = parseFloat(currentNode.getAttribute('data-catalog-price')) || 0;
            const inputQty = parseInt(currentNode.querySelector("[data-catalog-namespace='catalog/cart/product/qty']").value) || 0;

            const object = { code, qty: inputQty, price: inputPrice };
            if (callback) callback.apply(currentNode, [object]);
            return object;
        });
    }

    // Save cart data with a delay
    ajaxSaveCart(data, timing, callback) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.waiting.wait(true);
            const items = Object.fromEntries(data.map(val => [val.code, val]));

            const post = {
                items,
                result: callback ? 'html' : ''
            };

            fetch(this.baseUrl + '/save-cart', {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify(post)
            })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.text();
            })
            .then(data => callback && callback(data))
            .catch(console.error)
            .finally(() => this.waiting.wait(false));
        }, timing);
    }

    // Switch view between BOX and ROW
    ajaxSwitchView(type, callback) {
        if (["BOX", "ROW"].includes(type)) {
            this.waiting.wait(true);
            fetch(this.baseUrl + '/switch-view', {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify({ type })
            })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.text();
            })
            .then(data => callback && callback(data))
            .catch(console.error)
            .finally(() => this.waiting.wait(false));
        }
    }

    // Update the query string parameter
    updateQueryStringParameter(uri, key, value) {
        const separator = uri.includes('?') ? "&" : "?";
        const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
        return uri.match(re) ? uri.replace(re, `$1${key}=${value}$2`) : uri + separator + key + '=' + value;
    }

    // Event handler for click events
    clickEvent(event) {
        const trigger = event.target.closest('a, button');
        if (!trigger) return true; // No valid target
        // event.preventDefault();

        switch (true) {
            case trigger.matches('[data-action="switch-view-box"]'):
            case trigger.matches('[data-action="switch-view-row"]'):
                const mode = trigger.matches('[data-action="switch-view-box"]') ? 'BOX' : 'ROW';
                this.ajaxSwitchView(mode, data => {
                    document.querySelector('[data-catalog-namespace="catalog/switchview"]').innerHTML = data;
                    location.search = this.updateQueryStringParameter(location.search, 'page', '1');
                });
                break;
            case trigger.matches('[data-action="cart-item-add"]'):
            case trigger.matches('[data-action="cart-item-delete"]'):
                const row = trigger.closest('[data-catalog-namespace="catalog/cart/product"], [data-catalog-namespace="catalog/product"]');
                const qty = trigger.matches('[data-action="cart-item-add"]') ? '+1' : '0';
                const code = row.getAttribute('data-catalog-code');
                this.ajaxSaveCart([{ code, qty }], 10, html => {
                    document.querySelector('[data-catalog-namespace="catalog/cart/content"]').innerHTML = html;
                    this.updateCart();
                });
                break;
            default:
                return true; // Allow default behavior
        }
    }

    // Event handler for keyup events
    keyupEvent() {
        const data = this.updateCart();
        this.ajaxSaveCart(data, 1500);
    }

    // Update cart values
    updateCart() {
        let quantity = 0;
        let total = 0;

        const data = this.eachCartRow(object => {
            console.log('Updating cart');
            const rowPrice = document.querySelector('[data-catalog-namespace="catalog/cart/product/price"]');
            const rowTotalNode = document.querySelector('[data-catalog-namespace="catalog/cart/product/total"]');

            if (rowPrice) {
                rowPrice.textContent = object.price ? this.numbro(object.price).format({thousandSeparated: true, mantissa: 2}) : '';
            }

            quantity += object.qty;

            if (rowTotalNode) {
                const rowTotal = object.qty * object.price;
                rowTotalNode.textContent = this.numbro(rowTotal).format({thousandSeparated: true, mantissa: 2});
                total += rowTotal;
            }
        });

        this.container.dispatchEvent(new CustomEvent('catalog-cart-update', {
            bubbles: true,
            detail: { quantity, total }
        }));

        return data;
    }

    // Close the cart
    // closeCart() {
    //     // FIXME use this.modal
    //     $('[data-catalog-namespace="catalog/cart/modal"]').modal('hide');
    //     document.body.classList.remove('modal-open');
    //     const backdrop = document.querySelector('.modal-backdrop');
    //     if (backdrop) backdrop.remove();
    // }

    // Show the cart
    // showCart() {
    //     // FIXME use this.modal
    //     $('[data-catalog-namespace="catalog/cart/modal"]').modal(this.modalOptions);
    //     this.updateCart();
    // }

    // Initialize the catalog
    init() {
        console.log('Catalog initialization');
        this.container = document.querySelector('[data-catalog-namespace="catalog"]');
        this.baseUrl = this.container.getAttribute('data-catalog-baseurl');
        this.container.addEventListener('click', this.clickEvent.bind(this));
        this.container.addEventListener('keyup', this.keyupEvent.bind(this));
        this.container.addEventListener('catalog-cart-update', event => {
            const { quantity, total } = event.detail;
            const catalogCartNum = this.container.querySelector('[data-catalog-namespace="catalog/cart/trigger/num"]');
            if (catalogCartNum) catalogCartNum.textContent = quantity;

            const catalogCartTotal = this.container.querySelector('[data-catalog-namespace="catalog/cart/total"]');
            if (catalogCartTotal) catalogCartTotal.textContent = this.numbro(total).format({thousandSeparated: true, mantissa: 2});
        });
    }
}

export default Catalog;

// Usage
// const catalog = new Catalog(actions);
