class SocialShare {
    static defaults = {
        title: false,
        image: false,
        share: false,
        networks: []
    };

    static networks = {
        facebook: {
            icon: 'fab fa-facebook-square',
            url: 'https://www.facebook.com/sharer/sharer.php?u=|u|',
            encode: ['|u|'],
            label: 'Share on Facebook'
        },
        linkedin: {
            icon: 'fab fa-linkedin',
            url: 'https://www.linkedin.com/shareArticle?mini=true&url=|u|&title=|t.200|&summary=|d.256|&source=http%3A%2F%2Fwww.vimar.com',
            encode: ['|u|', '|t.200|', '|d.256|'],
            label: 'Share on LinkedIn'
        },
        whatsapp: {
            icon: 'fab fa-whatsapp-square',
            url: 'whatsapp://send?text=|u|',
            encode: ['|u|'],
            label: 'Share on WhatsApp'
        },
        pinterest: {
            icon: 'fab fa-pinterest-square',
            url: 'https://pinterest.com/pin/create/button/?url=|u|&description=|d|',
            encode: ['|u|', '|d|'],
            label: 'Share on Pinterest'
        },
        sendEmail: {
            icon: 'fa-solid fa-envelope-square',
            url: 'mailto:?subject=|t|&body=|u|',
            encode: ['|u|'],
            label: 'Send Email'
        },
        houzz: {
            icon: 'fab fa-houzz',
            url: 'https://www.houzz.com/imageClipperUpload?imageUrl=|i|&title=|t|&link=|u|',
            encode: ['|u|', '|i|', '|t|'],
            label: 'Share on Houzz'
        }
    };

    getDescription() {
        const meta = document.querySelector('meta[name="description"]');
        return meta ? meta.getAttribute('content').substring(0, 250) : '';
    }

    render(element, options) {
        this.element = element;
        this.settings = { ...SocialShare.defaults, ...options };
        this.params = {
            u: this.settings.share || location.href,
            t: this.settings.title || document.title,
            i: this.settings.image || '',
            d: this.getDescription()
        };

        const links = this.settings.networks.map(network => this.createSocialLink(network)).filter(Boolean);
        this.element.innerHTML = this.element.matches('ul') ? `<ul>${links.join('')}</ul>` : links.join('');
    }

    createSocialLink(network) {
        const social = SocialShare.networks[network];
        if (!social) return null;

        const url = this.buildUrl(social);
        return `
            <a href="${url}" title="${social.label}" target="_blank" rel="nofollow noopener">
                <i class="${social.icon} fa-2x fa-fw" aria-hidden="true"></i>
                <span class="visually-hidden">${social.label}</span>
            </a>
        `;
    }

    buildUrl(social) {
        return social.url.replace(/\|([a-z])\.?(\d+)?\|/g, (match, char, truncate) => {
            let param = this.params[char];
            if (truncate) {
                param = param.substring(0, parseInt(truncate));
            }
            return social.encode.includes(match) ? encodeURIComponent(param) : param;
        });
    }
}

export default SocialShare;

// Usage example:
// new SocialShare(document.querySelector('.target'), { networks: ['facebook', 'linkedin'], share: 'https://www.google.com', title: 'Google' });
