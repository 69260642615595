/* PasswordMeter.js */

class PasswordMeter {

    /**
     * @param {Waiting} waiting
     * @param {TemplateEngine} templateEngine
     */
    constructor(waiting, templateEngine) {
        this.waiting = waiting;
        this.templateEngine = templateEngine;

        this.wrapperNode  = null;

        this.templateEngine.getTemplateCollection()
            .add('pw-wrapper', `
                <div data-pw="wrapper" class="row g-1">
                    <div data-pw="password" class="col">
                        <%password%>
                    </div>
                    <div data-pw="icon" class="col-auto input-group-text">
                        <%icon%>
                    </div>
                    <div data-pw="meter" class="col-12">
                        <%meter%>
                    </div>
                </div>
            `)
            .add('pw-icon-visible', `
                <i class="fa-regular fa-fw fa-eye"></i>
            `)
            .add('pw-icon-hidden', `
                <i class="fa-regular fa-fw fa-eye-slash"></i>
            `)
            .add('pw-meter', `
                <div class="progress" role="progressbar" aria-label="Password security meter" aria-valuenow="<%value%>" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar overflow-visible text-dark" style="width: <%value%>%; background-color: var(<%bgcolor%>);"><%text%></div>
                </div>
            `)
        ;
    }

    /**
     * Set up the password strength meter, visibility toggle, and form submit spinner for a given input.
     * 
     * @param {HTMLInputElement} password
     */
    initialize(password) {
        const form = password.closest('form');

        /** @type {HTMLElement} */
        this.wrapperNode = this.templateEngine.renderToElement('pw-wrapper', {
            password: password.outerHTML,
            icon:     this.templateEngine.render('pw-icon-visible'),
            meter:    this.templateEngine.render('pw-meter', {value: 100, bgcolor: '--bs-green', text: 'Password Security Check'})
        });
        password.replaceWith(this.wrapperNode);

        this.getPwIconNode().addEventListener('click', () => {
            this.togglePassword();
            this.toggleIcon();
        });

        // Event listener for keyup event to calculate password strength
        this.getPwPassword().addEventListener('keyup', () => {
            this.updateStrengthMeter();
        });

        // Spinner for form submit (if not already added)
        if (form && !form.hasAttribute('data-spinner')) {
            form.setAttribute('data-spinner', 'submit');
            form.addEventListener('submit', this.handleFormSubmit.bind(this));
        }
    }

    /**
     * @returns {HTMLElement}
     */
    getPwPasswordNode() {
        return this.wrapperNode.querySelector('[data-pw="password"]');
    }
    
    /**
     * @returns {HTMLElement}
     */
    getPwIconNode() {
        return this.wrapperNode.querySelector('[data-pw="icon"]');
    }
    
    /**
     * @returns {HTMLElement}
     */
    getPwMeterNode() {
        return this.wrapperNode.querySelector('[data-pw="meter"]');
    }

    /**
     * @returns {HTMLInputElement}
     */
    getPwPassword() {
        return this.getPwPasswordNode().querySelector('input');
    }

    /**
     * @returns bool
     */
    isPasswordVisible() {
        return this.getPwPassword().getAttribute('type') !== 'password';
    }

    /**
     * Toggles password visibility by changing the input's type between 'password' and 'text'.
     */
    togglePassword() {
        if (this.isPasswordVisible() === true) {
            this.getPwPassword().setAttribute('type', 'password');
        } else {
            this.getPwPassword().setAttribute('type', 'text');
        }
    }

    /**
     * @param {HTMLInputElement} password
     */
    toggleIcon() {
        let iconHtml = null;
        if (this.isPasswordVisible() === true) {
            iconHtml = this.templateEngine.render('pw-icon-hidden');
        } else {
            iconHtml = this.templateEngine.render('pw-icon-visible');
        }
        this.getPwIconNode().innerHTML = iconHtml;
    }

    /**
     * Updates the password strength meter based on calculated strength.
     * @param {HTMLInputElement} password
    */
    updateStrengthMeter() {
        const strength = this.calculatePasswordStrength(this.getPwPassword().value);
        let meterHtml = this.templateEngine.render('pw-meter', {
            value: strength, 
            bgcolor: this.getBgColor(strength),
            text: `Secure ${strength}%`
        });
        this.getPwMeterNode().innerHTML = meterHtml;
    }

    /**
     * Calculates password strength based on various conditions.
     * @param {string} pw
     * @returns {number} A percentage indicating the strength.
     */
    calculatePasswordStrength(pw) {
        return /.{8,}/.test(pw) * (
            /.{12,}/.test(pw) +
            /[a-z]/.test(pw) +
            /[A-Z]/.test(pw) +
            /\d/.test(pw) +
            /[^A-Za-z0-9]/.test(pw) -
            /([A-Za-z])\1{3,}/.test(pw)
        ) * 20;
    }

    /**
     * Returns the appropriate CSS class based on password strength.
     * @param {number} strength
     * @returns {string}
     */
    getBgColor(strength) {
        if (strength < 20)   return '--bs-red';
        if (strength === 20) return '--bs-orange';
        if (strength === 40) return '--bs-teal';
        if (strength === 60) return '--bs-green';
        if (strength === 80) return '--bs-green';
        return '--bs-green';
    }

    /**
     * Sets up a spinner on form submit if the form is not already associated with one.
     * @param {Event} e
     */
    handleFormSubmit(e) {
        if (this.waiting) {
            this.waiting.wait(true);
        }
    }
}

export default PasswordMeter;


// // Register the class with DI container (example)
// (function() {
//     // Assuming you have a DI container setup
//     const waiting = container.resolve('waiting'); // Your DI container provides the 'waiting' service

//     // Register the PasswordMeter class
//     container.register('PasswordMeter', (container) => {
//         return new PasswordMeter(waiting);
//     });

//     // Instantiate the PasswordMeter and initialize it
//     const PasswordMeter = container.resolve('PasswordMeter');
// })();