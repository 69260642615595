class GeoData {
    constructor() {
        this.data = {}; // Initialize data as an empty object
    }

    /**
     * Load data from a remote URL.
     * @param {string} url - The URL to fetch data from.
     */
    async loadData(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            this.data = await response.json();
            console.log('Data loaded successfully:', this.data);
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }

    /**
     * Filter rows in the data based on a value, idVal, and idChildren.
     * @param {*} value - The value to search for.
     * @param {number} idVal - The index for matching the value.
     * @param {number} idChildren - The index of child nodes.
     * @returns {Array} The filtered rows.
     */
    filterRows(value, idVal, idChildren) {
        const find = (source, value, idVal, idChildren) => {
            return source.reduce((result, row) => {
                if (row[idVal] === value) result.push(row);
                if (Array.isArray(row[idChildren])) {
                    result.push(...find(row[idChildren], value, idVal, idChildren));
                }
                return result;
            }, []);
        };

        return find(this.data, value, idVal, idChildren);
    }

    /**
     * Populate a <select> element with data.
     * @param {Element} element - The SELECT element to populate.
     * @param {Array} rows - Array of data rows to populate from.
     * @param {string} labelIndex - The index of the label in the row.
     * @param {string} valueIndex - The index of the value in the row.
     */
    populateDropdown(element, rows, labelIndex, valueIndex) {
        if (element.tagName !== 'SELECT') return;

        element.innerHTML = ''; // Clear options
        element.appendChild(new Option()); // Add a null option

        rows
            .sort((a, b) => a[labelIndex].localeCompare(b[labelIndex])) // Sort by label
            .forEach(row => {
                const option = new Option(row[labelIndex], row[valueIndex]);
                element.appendChild(option);
            });
    }

    /**
     * Load provinces of a country into a SELECT element.
     * @param {Element} element - The SELECT element to populate.
     * @param {number} countryId - The country ID.
     */
    loadProvincesByCountry(element, countryId) {
        const rowCountry = this.filterRows(countryId, 0, 5).pop();
        if (rowCountry && Array.isArray(rowCountry[5])) {
            const provinces = rowCountry[5].flatMap(region => region[5]);
            this.populateDropdown(element, provinces, 1, 0);
        }
    }

    /**
     * Load provinces of a region into a SELECT element.
     * @param {Element} element - The SELECT element to populate.
     * @param {number} regionId - The region ID.
     */
    loadProvincesByRegion(element, regionId) {
        const rowRegion = this.filterRows(regionId, 0, 5).pop();
        if (rowRegion && Array.isArray(rowRegion[5])) {
            this.populateDropdown(element, rowRegion[5], 1, 0);
        }
    }

    /**
     * Load regions of a country into a SELECT element.
     * @param {Element} element - The SELECT element to populate.
     * @param {number} countryId - The country ID.
     */
    loadRegionsByCountry(element, countryId) {
        const rowCountry = this.filterRows(countryId, 0, 5).pop();
        if (rowCountry && Array.isArray(rowCountry[5])) {
            this.populateDropdown(element, rowCountry[5], 1, 0);
        }
    }
}

export default GeoData;

// Example usage:
// (async () => {
//     const geoData = new GeoData();
//     const url = `${window.location.protocol}//${window.location.host}/it/it/service/include/geodata`;
//     await geoData.loadData(url);
//     console.log('Geodata has been loaded');
// })();
